import React, {FC} from 'react';
import {graphql} from 'gatsby';

import {ICommonPageProps} from '@/core/dtos/ICommonPageProps';
import {IArticleNode} from '@/core/dtos/IArticle';
import Layout from '@/components/Layout/Layout';
import ProjectSeoHeader from '@/components/ProjectSeoHeader/ProjectSeoHeader';
import ArticlePage from '@/page-components/ArticlePage/ArticlePage';

interface IProps extends ICommonPageProps {
  data: {
    markdownRemark: IArticleNode;
  };
  pageContext: {
    id: string;
    categoryType: string;
    lang: string;
    url: string;
  };
}

const ArticleTemplate: FC<IProps> = ({data, location, pageContext: {url, lang}}) => {
  const {title, description, metatitle, metadescription} = data.markdownRemark.frontmatter;
  return (
    <Layout lang={lang} location={location} url={url}>
      <ProjectSeoHeader title={metatitle || title} description={metadescription || description} ogUrl={url} />
      <ArticlePage article={data.markdownRemark} />
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query Article($id: String!) {
    markdownRemark(frontmatter: {id: {eq: $id}}) {
      id
      html
      frontmatter {
        id
        type
        lang
        url
        date(formatString: "MMMM DD, YYYY")
        title
        description
        metatitle
        metadescription
        group
        next_steps
      }
    }
  }
`;
